<template>
    <v-container fluid>
        <v-form @submit.prevent="savePriceSubscription">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'price_subscription'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="time_subscription" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="time_subscription" :items="itemTimeSubscription"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingTimeSubscription" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-timetable" :label="$t('time_subscription')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="subscription_name" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="subscription_name" :items="itemNameSubscription"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingNameSubscription" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-clipboard-account-outline" :label="$t('subscription_name')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="type_subscription" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="type_subscription" :items="itemTypeSubscription"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingTypeSubscription" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-id-card" :label="$t('type_subscription')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="subscription_property" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="subscription_property" :items="itemSubscriptionProperty"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingSubscriptionProperty" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-account-child" :label="$t('subscription_property')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="price" name="price"
                                                    rules="required|min_value:1|max_value:99999999"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="price" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-cash-check"
                                                  :label="$t('price')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="guest_visit" name="price"
                                                    rules="required|min_value:0|max_value:365"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="guest_visit" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-calendar-month"
                                                  :label="$t('guest_visit')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="freezing" name="price"
                                                    rules="required|min_value:0|max_value:365"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="freezing" type="text" :error="!valid"
                                                  :error-messages="errors" :disabled="loading"
                                                  prepend-icon="mdi-calendar-month"
                                                  :label="$t('freezing')"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <v-switch v-model="towel" :disabled="loading"
                                          :label="$t('towel')"
                                          color="red" hide-details></v-switch>
                            </v-col>


                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="$t('deleted')"
                                          @change="deleted ? active = false : active"
                                          color="red" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted"
                                          :label="$t('published')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-6">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";

export default {
    name: 'PriceSubscriptionForm',
    components: {
        ValidationProvider,
        ValidationObserver
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            exist_translations: {},
            all_translations: true,
            loadingTimeSubscription: false,
            time_subscription: null,
            itemTimeSubscription: [],
            loadingTypeSubscription: false,
            subscription_name: null,
            itemNameSubscription: [],
            loadingNameSubscription: false,
            type_subscription: null,
            itemTypeSubscription: [],
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            price: null,
            active: false,
            deleted: false,
            towel: false,
            guest_visit: 0,
            freezing: 0,
            subscription_property: null,
            itemSubscriptionProperty: [],
            loadingSubscriptionProperty: false,
        }
    },
    computed: {
        ...mapGetters(['listLanguages',  'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
        this.getTimeSubscription()
        this.getTypeSubscription()
        this.getNameSubscription()
        this.getSubscriptionProperty()
    },
    methods: {
        setLanguage(val) {
            this.language = this.languages[this.tab]
            this.checkCreate()
        },
        checkCreate() {
            if (this.$route.name === "price_subscription.create") {
                this.heading = this.$t('price_subscription_creation')
            } else {
                this.heading = this.$t('price_subscription_editing')
                if (this.$route.params.id) {
                    this.getPriceSubscription()
                }
            }
        },
        async getSubscriptionProperty() {
            this.loadingSubscriptionProperty = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "search";
            await this.$http
                .get(`partner/subscription_property/`, {params: params})
                .then(res => {
                    this.itemSubscriptionProperty = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_subscription_properties'));
                    this.itemSubscriptionProperty = []

                })
                .finally(end => {
                    this.loadingSubscriptionProperty = false
                });
        },
        async getTimeSubscription() {
            this.loadingTimeSubscription = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "search";
            await this.$http
                .get(`partner/time_subscription/`, {params: params})
                .then(res => {
                    this.itemTimeSubscription = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_time_subscriptions'));
                    this.itemTimeSubscription = []

                })
                .finally(end => {
                    this.loadingTimeSubscription = false
                });
        },
        async getTypeSubscription() {
            this.loadingTypeSubscription = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "select";
            await this.$http
                .get(`partner/type_subscription/`, {params: params})
                .then(res => {
                    this.itemTypeSubscription = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_type_subscriptions'));
                    this.itemTypeSubscription = []

                })
                .finally(end => {
                    this.loadingTypeSubscription = false
                });
        },
        async getNameSubscription() {
            this.loadingNameSubscription = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "search";
            await this.$http
                .get(`partner/subscription_name/`, {params: params})
                .then(res => {
                    this.itemNameSubscription = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_name_subscriptions'));
                    this.itemNameSubscription = []

                })
                .finally(end => {
                    this.loadingNameSubscription = false
                });
        },
        async getPriceSubscription() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`partner/price_subscription/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.time_subscription = res.body.data.time_subscription_id
                    this.type_subscription = res.body.data.type_subscription_id
                    this.subscription_name = res.body.data.subscription_name_id
                    this.price = res.body.data.price
                    this.subscription_property = res.body.data.subscription_property_id
                    this.freezing = res.body.data.freezing
                    this.guest_visit = res.body.data.guest_visit
                    this.towel = res.body.data.towel
                    this.active = res.body.data.active
                    this.deleted = res.body.data.deleted
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_price_subscription'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async savePriceSubscription() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.price) {
                formData.append('price', this.price)
            }
            if (this.active) {
                formData.append('active', 1)
            }
            if (this.deleted) {
                formData.append('deleted', 1)
            }
            if (this.freezing) {
                formData.append('freezing', this.freezing)
            }
            if (this.guest_visit) {
                formData.append('guest_visit', this.guest_visit)
            }
            if (this.towel) {
                formData.append('towel', 1)
            }
            if (this.subscription_property) {
                if (this.subscription_property.id) {
                    formData.append('subscription_property', this.subscription_property.id)
                } else {
                    formData.append('subscription_property', this.subscription_property)
                }
            }
            if (this.type_subscription) {
                if (this.type_subscription.id) {
                    formData.append('type_subscription', this.type_subscription.id)
                } else {
                    formData.append('type_subscription', this.type_subscription)
                }
            }
            if (this.time_subscription) {
                if (this.time_subscription.id) {
                    formData.append('time_subscription', this.time_subscription.id)
                } else {
                    formData.append('time_subscription', this.time_subscription)
                }
            }
            if (this.subscription_name) {
                if (this.subscription_name.id) {
                    formData.append('subscription_name', this.subscription_name.id)
                } else {
                    formData.append('subscription_name', this.subscription_name)
                }
            }



            if (this.$route.params.id) {
                await this.$http
                    .put(`partner/price_subscription/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('price_subscription_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('price_subscription_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('partner/price_subscription', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('price_subscription_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'price_subscription.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'price_subscription'
                            })
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('price_subscription_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
